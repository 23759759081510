.my-partner-account-drawer {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin-right: -5px;
    @include fontSizeFactored(24px);
    color: $black;
    margin-left: 12px;
  }
}
