.standard-form-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 194px;
  margin-bottom: 8px;
  margin-right: 20px;

  &.widened-field {
    width: 408px;
  }

  &.three-fourth-field {
    width: 75%;
    margin-right: 0;

    label {
      margin-bottom: 6px;
    }
  }

  &.full-width-field {
    width: 100%;
    margin-right: 0;

    label {
      margin-bottom: 6px;
    }
  }

  &.extra-label-space {
    label {
      margin-bottom: 23px;
    }
  }

  &.radio-form-field {
    width: 100%;
    margin-right: 0;
    margin-bottom: 28px;

    label:not(.mat-radio-label) {
      @include fontSizeFactored(12px);
      margin-bottom: 20px;
    }

    mat-radio-button {
      margin-right: 20px;
    }

    .mat-radio-button .mat-radio-ripple {
      display: none;
    }

    .cdk-keyboard-focused {
      .mat-radio-label {
        padding: 1px 1px;
        background-color: $grey_lightest_opacity;
        border-radius: 8px;
        // border: 1px solid $primary;
      }
    }

    .mat-radio-disabled {
      .mat-radio-label-content {
        color: $grey_lighter;
        font-weight: 400;
        @include fontSizeFactored(12px);

        @include media("<phone") {
          font-size: 16px;
          padding: 8px 4px;
        }
      }

      .mat-radio-outer-circle {
        border-color: $grey_lightest;
      }

      .mat-radio-inner-circle {
        background-color: $grey_lightest;
      }
    }

    .mat-radio-checked {
      .mat-radio-label-content {
        color: $grey-darkest;
        font-weight: 400;
        @include fontSizeFactored(12px);

        @include media("<phone") {
          font-size: 16px;
          padding: 8px 4px;
        }
      }

      .mat-radio-outer-circle {
        border-color: $primary;
      }

      .mat-radio-inner-circle {
        background-color: $primary;
      }
    }

    .mat-radio-label-content {
      color: $grey;
      font-weight: 400;
      @include fontSizeFactored(12px);

      @include media("<phone") {
        font-size: 16px;
        padding: 8px 4px;
      }
    }

    .mat-radio-container,
    .mat-radio-inner-circle,
    .mat-radio-outer-circle {
      border-color: $tertiary;
      width: 15px;
      height: 15px;
    }

    .mat-radio-button:not(.mat-radio-checked):not(.mat-radio-disabled) {
      .mat-radio-label:hover {
        .mat-radio-label-content {
          color: $grey_dark;
          transition: color ease 280ms;
        }

        .mat-radio-outer-circle {
          border-color: $secondary;
        }

        .mat-radio-inner-circle {
          background-color: $secondary;
          transform: scale(0.5);
          opacity: 1;
          transition:
            transform ease 280ms,
            background-color ease 280ms;
        }
      }
    }
  }

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include fontSizeFactored(10px);
    line-height: 16px;
    color: $grey-dark;
    font-weight: 400;

    @include media("<phone") {
      white-space: unset;
    }
  }

  &.required-field {
    label {
      font-weight: 500;
      color: $grey-darkest;
    }
  }

  label.invalid {
    color: $negative;
  }

  mat-form-field {
    width: 100%;
    margin: 0;

    input {
      color: $grey_darkest;
      font-weight: 400;
      @include fontSizeFactored(12px);
      line-height: 14px;

      @include media("<phone") {
        font-size: 16px;
      }
    }

    .mat-hint {
      @include fontSizeFactored(11px);
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0.2em;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin: 0;
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline {
        background-color: $grey_lightest;
        border-radius: 10px;
      }
    }

    .mat-form-field-outline:not(.mat-form-field-outline-thick) {
      color: $grey_lightest;
    }

    .mat-form-field-outline-thick {
      color: $grey_lighter;
    }

    .mat-form-field-outline-start {
      border-radius: 8px 0 0 8px !important;
      min-width: 8px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 8px 8px 0 !important;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0.45em;

      @include media("<phone") {
        top: 0.3em;
      }
    }
  }

  .mat-select-arrow {
    width: 10px;
    height: 10px;
    border: none;
    background: url(/assets/img/chevron-down-blue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    margin: 0 4px;
    margin-bottom: -5px;

    transition: transform 100ms;
  }

  .mat-select[aria-expanded="true"] .mat-select-arrow {
    margin-bottom: -5px;
    height: 8px;
    transform: rotate(180deg);
  }

  .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;
    bottom: 14px;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  .ng-select .ng-arrow-wrapper {
    height: 10px;
    width: 10px;
    background-image: url(/assets/img/chevron-down-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 5px;
    transition: transform 100ms;
  }

  .ng-select-opened .ng-arrow-wrapper {
    transform: rotate(180deg);
  }
}

.ant-form-item {
  &.has-hint {
    margin-bottom: 0;
  }
}

.ant-form-item-explain {
  @include fontSizeFactored(12px);
  color: $grey_darkest;
  line-height: 1.2;
  margin: 5px 2px 5px 5px;

  .ant-form-item-explain-error {
    color: $negative;
  }
}
