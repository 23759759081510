$black: #191b1c;
$grey_darkest: #3e4951;
$grey_dark: #626e79;
$grey: #88949f;
$grey_lighter: #becbd7;
$grey_lightest: #e5ecf2;
$grey_lightest_opacity: #e5ecf297;
$grey_lightest_strong_opacity: #e5ecf244;
$white: #fff;

$primary: #2b5ee0;
$primary_dark: #2426c0;
$action: #5966e2;
$action_opacity: #5967e2a1;
$secondary: #3fb5f8;
$secondary_opacity: #3fb4f821;
$tertiary: #5bd7df;
$tertiary_opacity: #5bd6df87;

$positive: #2ac49f;
$negative: #d75f6d;
$negative_lighter: #d75f6d63;
$negative_light: #d75f6d38;
$objective: #dcd652;
$objective_dark: #b9b447;
$objective_darkest: #ada501;

$category-temp-premit: #314243;
$category-perm-stay: #313543;
$category-long-resi: #313243;

@mixin gradBg() {
  // background: linear-gradient(0deg, #2b5ee0 0.01%, #5bd7df 100%);

  background: linear-gradient(0deg, rgba(0, 38, 86, 1) 0%, rgba(10, 63, 130, 1) 5%, rgba(7, 79, 171, 1) 70%, rgba(118, 177, 255, 1) 100%);
  mix-blend-mode: normal;
}

@mixin fontSizeFactored($base) {
  font-size: calc($base + var(--font-factor));
}

$menuSideBarWidth: 241px;
$contentColumnWidth: calc(100vw - $menuSideBarWidth);
$topBarPaddingTop: 20px;
$topBarHeight: calc(60px - $topBarPaddingTop);
$topBarTotalHeight: calc($topBarHeight + $topBarPaddingTop);

$bottomFixedBarTotalHeight: 70px;
// OLD COLORS
$primary-text: #064890;
$accent-blue: #06499023;
$hover-green: #64b4506b;
$confirmation-green: #64b450;
$confirmation-green-dark: #4b883b;
$error-red: #bd1f00;
$error-red-neon: #ff4800;
$strong-grey: #727272;
$utils-grey: #919090;
$utils-grey-light: #e7e7e7;
$warn-orange: #ea7200;
$warn-orange-light: #f0a35c;
$error-red-light: #bd1f0028;
