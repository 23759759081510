.app-ant-switch-big {
  .ant-switch {
    height: 40px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 30px);
  }
  .ant-switch-handle {
    top: 6px;
    width: 28px;
    height: 28px;

    &::before {
      border-radius: 28px;
    }
  }
}
