.mat-snack-bar-container.bridge-snack {
  padding: 0;
  min-height: 40px;
  min-width: 529px;
  max-width: 600px;

  margin: 24px 24px 96px 24px;
  border-radius: 8px;

  @include media("<phone") {
    min-width: unset;
    max-width: unset;
  }

  &.error-snack {
    background-color: $negative;
    color: $white;
    min-width: 529px;
    max-width: 55vw;
    width: 55vw;

    @include media("<phone") {
      min-width: unset;
      max-width: unset;
      width: 100%;
    }

    .mat-simple-snackbar-action {
      color: $white;
      background-color: $negative;
    }
  }

  &.warning-snack {
    background-color: $objective_dark;
    color: $white;

    .mat-simple-snackbar-action {
      color: $white;
      background-color: $objective;
    }
  }

  &.info-snack {
    background-color: $positive;
    color: $white;

    .mat-simple-snackbar-action {
      color: $white;
      background-color: $positive;
    }
  }
}
