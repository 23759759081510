.standard-guide-step {
  margin: 20px !important;

  &.shepherd-element {
    max-width: 420px;
  }

  &.shepherd-has-title {
    .shepherd-content .shepherd-header {
      padding: 10px 1.5em;
      background-color: $grey_lightest;

      .shepherd-title {
        @include fontSizeFactored(15px);
        color: $grey_dark;
        margin: 0;
      }
    }
  }

  .shepherd-text {
    @include fontSizeFactored(13px);
    color: $grey_dark;
    padding: 1.5em 1.5em 0.3em 1.5em;
  }

  .shepherd-footer {
    margin-top: 0.3em;

    .shepherd-button {
      @include fontSizeFactored(11px);

      &.standard-back-button,
      &.standard-cancel-button {
        background-color: $grey_lightest;
        color: $grey_dark;
      }
      &.standard-done-button,
      &.standard-next-button {
        background-color: $secondary;
        color: $white;
      }

      &.counter-button {
        padding: 0.2rem 1rem;
        background-color: $white;
        color: $grey;
        margin-right: 15px;
        cursor: unset;
      }
    }
  }

  .shepherd-content {
    .centered {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .gif-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0 0 0;

      &.bigger-gif {
        img {
          max-width: 290px;
        }
      }

      img {
        max-width: 160px;
      }
    }

    .centered-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 5px 0;
      }

      img {
        width: 28px;
        margin-right: 5px;
      }
    }
  }
}
