.partner-points-circle-popover {
  width: 320px;
  @include fontSizeFactored(12px);
  line-height: 18px;
  color: $grey_darkest;

  @include media("<desktop") {
    width: 230px;
    @include fontSizeFactored(11px);
    line-height: 18px;
  }

  .ant-popover-inner {
    border-radius: 10px;
  }
  .ant-popover-title {
    border-radius: 10px 10px 0 0;
    text-transform: uppercase;
  }

  &.pending-verification-points {
    .ant-popover-title {
      background-color: $grey_lighter;
      color: $grey_darkest;
    }
  }

  &.granted-points {
    .ant-popover-title {
      background-color: $positive;
      color: $white;
    }
  }

  &.paid-out-points {
    .ant-popover-title {
      background-color: $primary;
      color: $white;
    }
  }
}
