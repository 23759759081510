/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e6ecfb,
    100 : #bfcff6,
    200 : #95aff0,
    300 : #6b8ee9,
    400 : #4b76e5,
    500 : #2b5ee0,
    600 : #2656dc,
    700 : #204cd8,
    800 : #1a42d3,
    900 : #1031cb,
    A100 : #f9faff,
    A200 : #c6ceff,
    A400 : #93a2ff,
    A700 : #7a8dff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #e8f6fe,
    100 : #c5e9fd,
    200 : #9fdafc,
    300 : #79cbfa,
    400 : #5cc0f9,
    500 : #3fb5f8,
    600 : #39aef7,
    700 : #31a5f6,
    800 : #299df5,
    900 : #1b8df3,
    A100 : #ffffff,
    A200 : #f3f9ff,
    A400 : #c0e0ff,
    A700 : #a7d3ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-tertiary: (
    50 : #ebfafb,
    100 : #cef3f5,
    200 : #adebef,
    300 : #8ce3e9,
    400 : #74dde4,
    500 : #5bd7df,
    600 : #53d3db,
    700 : #49cdd7,
    800 : #40c7d2,
    900 : #2fbeca,
    A100 : #ffffff,
    A200 : #dafcff,
    A400 : #a7f7ff,
    A700 : #8ef5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-positive: (
    50 : #e5f8f3,
    100 : #bfede2,
    200 : #95e1cf,
    300 : #6ad5bc,
    400 : #4accad,
    500 : #2ac39f,
    600 : #25bd97,
    700 : #1fb58d,
    800 : #19ae83,
    900 : #0fa172,
    A100 : #d0ffef,
    A200 : #9dffdd,
    A400 : #6affcb,
    A700 : #51ffc2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-negative: (
    50 : #faeced,
    100 : #f3cfd3,
    200 : #ebafb6,
    300 : #e38f99,
    400 : #dd7783,
    500 : #d75f6d,
    600 : #d35765,
    700 : #cd4d5a,
    800 : #c74350,
    900 : #be323e,
    A100 : #ffffff,
    A200 : #ffd1d5,
    A400 : #ff9ea5,
    A700 : #ff858e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-objective: (
    50 : #fbfaea,
    100 : #f5f3cb,
    200 : #eeeba9,
    300 : #e7e286,
    400 : #e1dc6c,
    500 : #dcd652,
    600 : #d8d14b,
    700 : #d3cc41,
    800 : #cec638,
    900 : #c5bc28,
    A100 : #ffffff,
    A200 : #fffcd0,
    A400 : #fff99d,
    A700 : #fff784,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

