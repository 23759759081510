.application-select-wrapper {
  $borderRadius: 6px;
  $borderWidth: 1px;
  $borderColor: $grey_lightest_opacity;
  margin: 50px 0 0 0;

  .standard-form-field {
    @include media("<=desktop") {
      width: 100%;
    }
    @include media(">=desktop") {
      width: 225px;
      padding-left: 10px;
    }
  }

  .select-trigger-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .user-process-selected-option-label {
      @include fontSizeFactored(13px);
      color: $grey_lighter;
      line-height: 38px;
      font-weight: 400;
    }

    .user-process-selected-option {
      @include fontSizeFactored(18px);
      color: #fff;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include media("<=desktop") {
        width: auto;
      }
      @include media(">=desktop") {
        width: 190px;
      }
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      padding-right: 10px;
      padding-left: 10px;
    }

    .mat-form-field-outline-thick {
      color: rgba(0, 0, 0, 0);
      border: 1px solid $borderColor;
    }

    .mat-form-field-outline {
      // background-color: $borderColor;
      border-radius: $borderRadius;
    }

    .mat-form-field-outline-start {
      border-radius: $borderRadius 0 0 $borderRadius !important;
      min-width: $borderRadius !important;
      border-width: $borderWidth;
    }

    .mat-form-field-outline-end {
      border-radius: 0 $borderRadius $borderRadius 0 !important;
      border-width: $borderWidth;
    }

    .mat-form-field-outline:not(.mat-form-field-outline-thick) {
      color: $borderColor;
      // background-color: $borderColor;
      border-width: $borderWidth;
      border-radius: $borderRadius;
    }

    .mat-form-field-infix {
      padding: 1.5em 0 3em 0;
    }

    .mat-select-arrow-wrapper {
      display: none;
      transform: translate(20%, 50%);
    }
  }
}

.main-applications-select.mat-select-panel {
  margin-top: 35px !important;
  border-radius: 10px;
  max-height: 400px;
  max-width: 600px !important;
  background: #fff !important;

  @include media("<desktop") {
    max-width: 20vw !important;
  }

  @include media("<400px") {
    margin-top: unset !important;
    max-height: 300px;
  }

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: $grey_lightest;

    .main-label {
      color: $primary;
      font-weight: 500;
    }
  }

  .mat-option.mat-option-disabled {
    background: $grey_lighter;
    color: $grey;
    opacity: 0.4;
  }

  .mat-option:hover:not(.mat-option-disabled) {
    background: $grey_lightest_opacity;

    .select-item-column-icon {
      color: $primary;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin: 5px 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
  }
}

.applications-select-option-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $grey_lightest;

  .select-item-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 25px;

    &-icon {
      padding: 15px 5px;
      width: 20px;
    }
  }

  .main-label {
    @include fontSizeFactored(14px);
    color: $grey_dark;
    margin: 0;
    line-height: 25px;
  }

  .status-label {
    @include fontSizeFactored(11px);
    margin: 0;
    line-height: 25px;
    color: $grey_dark;

    &.positive {
      color: $positive;
    }

    &.negative {
      color: $negative;
    }
  }

  .sub-label {
    @include fontSizeFactored(11px);
    color: $grey;
    margin-top: 5px;
    line-height: 15px;

    &.services {
      margin-top: 10px;
      max-width: 320px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
