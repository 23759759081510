.calendarFilter {
  button.immigration-office-visit::before {
    content: '';
    display: block;
    width: 10%;
    height: 10%;
    background: #e4eaf0;
    border-radius: 50%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  button.company-office-planned-visit::before {
    content: '';
    display: block;
    width: 10%;
    height: 10%;
    background: rgba(89, 102, 226, 0.3607843137);
    border-radius: 50%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  button.immigration-office-visit.company-office-planned-visit::before {
    content: '';
    display: block;
    width: 10%;
    height: 10%;
    background: #e4eaf0;
    position: absolute;
    top: 15%;
    bottom: unset;
    left: 40%;
    transform: translateX(-50%);
    z-index: 1;
    border-radius: 50%;
  }

  button.immigration-office-visit.company-office-planned-visit::after {
    content: '';
    display: block;
    width: 10%;
    height: 10%;
    background: rgba(89, 102, 226, 0.3607843137);
    position: absolute;
    top: 15%;
    bottom: unset;
    left: 60%;
    transform: translateX(-50%);
    z-index: 1;
    border-radius: 50%;
  }

  .mat-calendar-spacer {
    width: calc(50% - 40px);
    flex: unset;
  }

  .mat-calendar-body-selected {
    color: rgba(0, 0, 0, 0.87);
    background-color: unset;
    border: 2px solid $secondary;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border: unset;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .mat-calendar-body-today.mat-calendar-body-selected {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid $secondary;
  }
}
