.main-support-chats-list-loading-indicator.ant-spin-nested-loading {
  div.ant-spin {
    font-size: 70px;
    top: 30%;
  }
}

.chat-item-avatar-loading-indicator.ant-spin-nested-loading {
  div.ant-spin {
    font-size: 30px;
    line-height: 0%;
    top: 0;
  }
}

.ant-dropdown-menu-item.attention-marker-item {
  padding: 12px 16px;

  .marker-icon {
    margin-right: 15px;

    &.icon-attention {
      color: $negative;
    }
    &.icon-later {
      color: $objective;
    }
    &.icon-done {
      color: $positive;
    }
    &.icon-grey {
      color: $grey_dark;
    }
  }
}
